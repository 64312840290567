import { isAfter, isWithinInterval, startOfToday, subWeeks } from 'date-fns'
import { Event } from 'react-big-calendar'
import format from 'date-fns/format'
import { TOrdersCalendarDataItem } from '../types'
import {
  dateFromDotDateFormat,
  dotDateFormat,
  endOfWeekCustom,
  weekDays
} from '../../../../shared/utils/date'
import ReviewWeeklyReport from '../components/events/ReviewWeeklyReport/ReviewWeeklyReport'
import styles from '../components/Calendar/Calendar.module.scss'
import QuantityDetails from '../components/events/QuantityDetails/QuantityDetails'
import EditWeeklyDraft from '../components/events/EditWeeklyDraft/EditWeeklyDraft'
import ReadOnlyWeeklyReportButton from '../components/events/ReadOnlyWeeklyReportButton/ReadOnlyWeeklyReportButton'
import AddWeeklyReport from '../components/events/AddWeeklyReport/AddWeeklyReport'
import QuantityDailyOrders from '../components/events/QuantityDailyOrders/QuantityDailyOrders'
import AddNewDailyOrder from '../components/events/AddNewDailyOrder/AddNewDailyOrder'
import { dayOfWeek } from '../../../../shared/constants/date'

export function isWeeklyItem({ orderType }: TOrdersCalendarDataItem) {
  return orderType === 'WEEKLY'
}

export function isNegotiatingItem({ orders, editableForCurrentUser }: TOrdersCalendarDataItem) {
  return orders.some((order) => order.status !== 'DRAFT') && editableForCurrentUser
}

export function isDraftItem({ orders }: TOrdersCalendarDataItem) {
  return orders.every((order) => order.status === 'DRAFT')
}
export function isConfirmedItem({ orders, editableForCurrentUser }: TOrdersCalendarDataItem) {
  return orders.some((order) => order.status !== 'DRAFT')
}

export function isDailyItem({ orderType }: TOrdersCalendarDataItem) {
  return orderType === 'DAILY'
}

function eventReview(item: TOrdersCalendarDataItem): Event {
  return {
    start: dateFromDotDateFormat(item.startDate),
    end: endOfWeekCustom(dateFromDotDateFormat(item.endDate)),
    title: <ReviewWeeklyReport item={item} />
  }
}

function eventDraft(item: TOrdersCalendarDataItem): Event {
  return {
    start: dateFromDotDateFormat(item.startDate),
    end: endOfWeekCustom(dateFromDotDateFormat(item.endDate)),
    title: <EditWeeklyDraft item={item} />
  }
}

function eventReadOnly(item: TOrdersCalendarDataItem): Event {
  return {
    start: dateFromDotDateFormat(item.startDate),
    allDay: true,
    end: endOfWeekCustom(dateFromDotDateFormat(item.endDate)),
    title: <ReadOnlyWeeklyReportButton item={item} />
  }
}

function eventAddCopy(
  weekStartDate: Date
): Event {
  return {
    start: weekStartDate,
    end: endOfWeekCustom(weekStartDate),
    title: <AddWeeklyReport weekStartDate={weekStartDate}/>
  }
}

const confirmedWeek = (items: TOrdersCalendarDataItem[], date: Date) =>
  weeklyConfirmedItems(items).find(
    ({ startDate }) => weekDays(dateFromDotDateFormat(startDate))
      .map((weekDate) => dotDateFormat(weekDate))
      .includes(dotDateFormat(date))
  )

const emptyDay = (items: TOrdersCalendarDataItem[], date: Date) =>
  dailyItems(items).find((dailyOrder) => dailyOrder.startDate === dotDateFormat(date))

export function weeklyEvents(
  items: TOrdersCalendarDataItem[],
  weeksStartDates: Date[],
  allowToCreate: boolean
) {
  const negotiatingWeeklyItem = (weekStartDate: Date) => items
    .filter(isWeeklyItem)
    .filter(isNegotiatingItem)
    .find(({ startDate }) => startDate === dotDateFormat(weekStartDate))

  const draftWeeklyItem = (weekStartDate: Date) => items
    .filter(isWeeklyItem)
    .filter(isDraftItem)
    .find(({ startDate }) => startDate === dotDateFormat(weekStartDate))

  return weeksStartDates.reduce<Event[]>((result, weekStartDate) => {
    const foundItemNegotiate = negotiatingWeeklyItem(weekStartDate)
    const foundItemDraft = draftWeeklyItem(weekStartDate)
    const foundConfirmWeek = confirmedWeek(items, weekStartDate)
    const endOfPreviousWeek = endOfWeekCustom(subWeeks(new Date(), 1))

    return [
      ...result,
      ...(
        foundItemNegotiate
          ? [eventReview(foundItemNegotiate)]
          : foundItemDraft ? [eventDraft(foundItemDraft)]
            : foundConfirmWeek ? [eventReadOnly(foundConfirmWeek)]
              : isAfter(weekStartDate, endOfPreviousWeek) && allowToCreate
                ? [eventAddCopy(weekStartDate)]
                : []
      )
    ]
  },
  [])
}

export function eventDaily(
  date: Date,
  item?: TOrdersCalendarDataItem

): Event {
  return {
    end: date,
    start: date,
    title:
  <div className={styles.buttonsWrapper}>
    {item && <QuantityDailyOrders item={item}/>}
    {item && <QuantityDetails item={item}/>}
  </div>
  }
}

function eventAddDaily(date: Date): Event {
  return { end: date, start: date, title: <AddNewDailyOrder date={date} /> }
}

export function dailyItems(items: TOrdersCalendarDataItem[]) {
  return items.filter(isDailyItem)
}

export function weeklyConfirmedItems(items: TOrdersCalendarDataItem[]) {
  return items
    .filter(isWeeklyItem)
    .filter(isConfirmedItem)
}

export function dailyEvents(
  items: TOrdersCalendarDataItem[],
  calendarDates: Date[],
  sellerDays: string[]
) {
  const dailyItemByDate = (date: Date) =>
    dailyItems(items).find(({ startDate }) => startDate === dotDateFormat(date))

  return calendarDates.reduce<Event[]>(
    (result, date) => {
      const foundConfirmedWeek = confirmedWeek(items, date)
      const foundedDailyOrder = emptyDay(items, date)
      const isSellerDeliveryDay = sellerDays.includes(format(date, dayOfWeek).toUpperCase())
      const isAvailableDay = isAfter(date, startOfToday())

      return (
        [
          ...result,
          ...(
            foundConfirmedWeek && foundedDailyOrder
              ? [eventDaily(date, dailyItemByDate(date))]
              : isSellerDeliveryDay && isAvailableDay ? [eventAddDaily(date)] : []
          )
        ]
      )
    },
    []
  )
}

export function preparedDailyEvents(dailyEvents: Event[], weeklyEvents: Event[]) {
  return dailyEvents
    .filter(({ start: dailyStart }) => (
      weeklyEvents.some(
        ({ start, end }) =>
          start
          && end
          && dailyStart
          && isWithinInterval(dailyStart, { start, end })
      )
    ))
}
